<template>
  <div class="ctfCenterAlls">
    <div class="ctfCenterFirst">
      <div class="ctfCenterFirst1">
        <h2>CTF竞赛平台</h2>
        <h3>以建设幸福为出发点加强网络安全监管工作</h3>
        <h3>
          坚持积极防御、综合防范的方针，全面提高信息安全防护能力，重点保障基础信息网络和重要信息系统安全
        </h3>
      </div>
    </div>
    <div class="box">
      <ul class="tabs clearfix">
        <li v-for="(tab, index) in tabsName" :key="index">
          <span
            class="tab-link"
            @click="tabsSwitch(index)"
            :class="active == index ? 'active' : ''"
            >{{ tab.name }}</span
          >
        </li>
      </ul>
    </div>
    <div class="d_jump"></div>
    <div class="ctfCenterThird">
      <h1>产品概述</h1>
      <div class="ctfCenterThird1">
        <div class="ctfCenterThird2">
          <div class="ctfCenterThird2Left">
            <p>
              CTF（Capture The
              Flag）中文一般译作夺旗赛，在网络安全领域中指的是网络安全技术人员之间进行技术竞技的一种比赛形式。CTF起源于1996年DEFCON全球黑客大会，以代替之前黑客们通过互相发起真实攻击进行技术比拼的方式。已经成为全球范围网络安全圈流行的竞赛形式，2013年全球举办了超过五十场国际性CTF赛事
            </p>
            <p>
              为深入贯彻习近平总书记关于二十大提出的网络强国的重要思想，围绕建设网络强国的战略部署
              建设网络强国的战略部署要与“两个⼀百年”奋斗目标同步推进，由御之安承办UNCTF2022网络安全大赛将,以赛事为契机，提升网络安全保障能力，汇聚高端网络安全人才，共筑网络安全防线，为中国网络安全事业发展提供智力支撑和保障。
            </p>
          </div>
          <div class="ctfCenterThird2Right">
            <img src="../../../../assets/img/proctCenter/1.webp" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="d_jump"></div>
    <div class="ctfCenterFourth">
      <div class="ctfCenterFourth1">
        <h1>产品功能</h1>
        <div class="ctfCenterFourth11 wow fadeInUp" id="newctfCenterFourth">
          <div class="ctfCenterFourth1-1">
            <div class="ctfCenterFourth1-1-1">
              <h3>日常训练</h3>
              <img src="../../../../assets/img/proctCenter/2.jpg" alt="" />
            </div>
            <p>
              用户平时的训练平台提供的赛题都来自于国内各大高校以及实验室，保证赛题来源最真实的现实场景
            </p>
            <div class="ctfCenterFourth1-2"></div>
          </div>
          <div class="ctfCenterFourth1-1">
            <div class="ctfCenterFourth1-1-1">
              <h3>比赛列表</h3>
              <img src="../../../../assets/img/proctCenter/2.jpg" alt="" />
            </div>
            <p>平台对标最前沿的赛事竞技环境，确保用户体验最真实的竞技场景</p>
            <div class="ctfCenterFourth1-2"></div>
          </div>
          <div class="ctfCenterFourth1-1">
            <div class="ctfCenterFourth1-1-1">
              <h3>校内CTF赛</h3>
              <img src="../../../../assets/img/proctCenter/2.jpg" alt="" />
            </div>
            <p>用户来自各大高校的精英，通过我司提供的平台进行激烈的对抗比赛</p>
            <div class="ctfCenterFourth1-2"></div>
          </div>
          <div class="ctfCenterFourth1-1">
            <div class="ctfCenterFourth1-1-1">
              <h3>公开赛</h3>
              <img src="../../../../assets/img/proctCenter/2.jpg" alt="" />
            </div>
            <p>
              用户大多来自社会人士，也可在我司的平台上参加比赛，通过充分参与比赛来获得个人的经验，并进行交流和分享，在实践中体悟和理解、内化相关知识，然后通过反思、总结，提升为理性知识。
            </p>
            <div class="ctfCenterFourth1-2"></div>
          </div>
          <div class="ctfCenterFourth1-1 animated">
            <div class="ctfCenterFourth1-1-1">
              <h3>个人中心</h3>
              <img src="../../../../assets/img/proctCenter/2.jpg" alt="" />
            </div>
            <p>
              通过用户中心进行个人密码的修改，邮箱的修改，密保以及第三方的身份绑定
            </p>
            <div class="ctfCenterFourth1-2"></div>
          </div>
          <div class="ctfCenterFourth1-1 animated">
            <div class="ctfCenterFourth1-1-1">
              <h3>我的队伍</h3>
              <img src="../../../../assets/img/proctCenter/2.jpg" alt="" />
            </div>
            <p>
              用户通过队伍页面可进行组建队伍成功后报名对应的比赛，并且可在本页面查看自己队伍的排名以及其他队伍的排名
            </p>
            <div class="ctfCenterFourth1-2"></div>
          </div>
        </div>
        <div class="d_jump"></div>
      </div>
    </div>

    <div class="ctfCenterFiveth">
      <div class="ctfCenterFiveth1">
        <h1>产品优势</h1>
        <div class="ctfCenterFiveth11">
          <div class="ctfCenterFiveth1-1 wow fadeInRight">
            <div class="ctfCenterFiveth1-1-1">
              <img src="../../../../assets/img/proctCenter/2.jpg" alt="" />
              <h3>前后分离</h3>
            </div>
            <p>
              管理端与平台分离，可以分开部署，避免因为一起部署出现的bug。通过前端路由的配置，可以轻松实现页面的按需加载，服务器不用解析前端页面，因此，在页面交互及用户体验上也会得到提升。前后端分离后，分层明确，应用代码变得整洁清晰。
            </p>
          </div>
          <div class="ctfCenterFiveth1-1 wow fadeInUp">
            <div class="ctfCenterFiveth1-1-1">
              <img src="../../../../assets/img/proctCenter/2.jpg" alt="" />
              <h3>动态Flag</h3>
            </div>
            <p>
              支持动态flag在一定程度上可以防止py,可以有效的减少选手交互Flag、获取他人Flag的行为，让选手更加专注于题目本身，放弃从其他非解题途径获得Flag的幻想，并且还能进一步发现选手可能存在的作弊行为，以便于对作弊选手进行警告、屏蔽、放开等。
            </p>
          </div>
          <div class="ctfCenterFiveth1-1 wow fadeInLeft">
            <div class="ctfCenterFiveth1-1-1">
              <img src="../../../../assets/img/proctCenter/2.jpg" alt="" />
              <h3>页面形象</h3>
            </div>
            <p>
              前端图形化界面简洁明了，每个题目以折线的形式进行展示，比较美观。前端直观，计分板设计完善，适合作为团队日常训练平台。功能模块直观，搭建简单运行速度快，功能模块直接明了方便用户快速上手。
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="d_jump"></div>
    <div class="ctfCenterSixTh">
      <h1>用户价值</h1>
      <div class="ctfCenterSixTh1 wow fadeInUp">
        <div class="ctfCenterSixTh1Left">
          <h2>价值提升</h2>
          <p>
            平台所有的赛题都来自于国内各大高校以及实验室，赛题来源最真实的现实场景，保证所有用户使用过程中能得到最大的提升。
          </p>
        </div>
        <div class="ctfCenterSixTh1Right">
          <img src="../../../../assets/img/proctCenter/3.webp" alt="" />
        </div>
      </div>
      <p>
        很多第一次参加ctf，大多为学生，周围也没有那么多的技术人员，但是在ctf上可以快速提高自己的能力，督促自己去学习。
      </p>
    </div>
    <div class="d_jump"></div>
    <div class="codeAuditThird">
      <h1>典型场景</h1>
      <div class="codeAuditThird1">
        <ul>
          <li>
            <div class="codeAuditThird1-1">
              <img
                src="https://www.boundaryx.com/wp-content/uploads/2022/05/11-1.webp"
                alt=""
              />
            </div>
            <div class="codeAuditThird1-2">
              <p class="codeAuditThird1-2-1">行业举办比赛</p>
              <span class="codeAuditThird1-2-2">
                　行业举办的CTF行业比赛旨在为行业内的人员提供展示自己网络安全技能的机会，同时也为行业内的公司和机构提供了交流的平台。此外，行业举办的CTF比赛还有助于促进行业内的网络安全技术进步和创新，为行业发展做出贡献。
              </span>
            </div>
          </li>
          <li>
            <div class="codeAuditThird1-1">
              <img
                src="https://www.boundaryx.com/wp-content/uploads/2022/05/22-2.webp"
                alt=""
              />
            </div>
            <div class="codeAuditThird1-2">
              <p class="codeAuditThird1-2-1">校内举办比赛</p>
              <span class="codeAuditThird1-2-2">
                校内举办的CTF校内比赛旨在为学生提供展示自己网络安全技能的机会，同时也为学校提供了培养学生综合素质的平台。校内比赛通常会吸引全校的学生前来参与或观看，为参赛者提供宝贵的机会获得学校内的认可和奖励。此外，校内举办的CTF比赛还有助于促进学生之间的竞争意识和团队协作精神，为学校的教育工作做出贡献。
              </span>
            </div>
          </li>
          <li>
            <div class="codeAuditThird1-1">
              <img
                src="https://www.boundaryx.com/wp-content/uploads/2022/05/33-1.webp"
                alt=""
              />
            </div>
            <div class="codeAuditThird1-2">
              <p class="codeAuditThird1-2-1">企业举办比赛</p>
              <span class="codeAuditThird1-2-2">
                企业举办CTF比赛是一种非常有效的方式来提升员工的技术能力，同时也是一种有趣的团队建设活动。
                企业举办CTF比赛的方式有很多种，可以在公司内部举办，也可以联合其他企业一起举办。无论采用哪种方式，重要的是要确保比赛公平、有趣、富有挑战性。
                总的来说，企业举办CTF比赛是一种很好的员工培训和团队建设活动，可以帮助员工提升技能、增强团队协作能力，并为公司带来更多的竞争优势。
              </span>
            </div>
          </li>
          <li>
            <div class="codeAuditThird1-1">
              <img
                src="https://www.boundaryx.com/wp-content/uploads/2022/05/44.webp"
                alt=""
              />
            </div>
            <div class="codeAuditThird1-2">
              <p class="codeAuditThird1-2-1">政府举办省赛</p>
              <span class="codeAuditThird1-2-2">
                政府举办的CTF省赛是一项重要的活动，旨在为当地的学生和业界人士提供展示自己网络安全技能的机会，参赛的人员包括各种年龄段的学生和业界人士，从少年儿童到中年人都有参赛的机会。此外，政府举办的CTF省赛还为当地的学生和业界人士提供了宝贵的机会，可以与其他地区的人员竞争，提升自己的实力。
              </span>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!-- <div class="ctfCenterSevenTh">
      <h1>典型场景</h1>
      <div class="ctfCenterSevenTh1 wow fadeInUp">
        <div class="ctfCenterSevenTh1Left">
          <h2>平台应用</h2>
          线上赛：CTF解题。参赛队伍通过互联网在线解题，题目分为 Web
          漏洞挖掘与渗透(Web)，软件逆向
          (Reverse)，二进制漏洞挖掘和利用(PWN)，密码学（Crypto）与杂项（Misc）等。每种类型的题有不同的分值，参赛队通过获得
          flag 从而得到对应的分值。
          答题积分采用国际惯用的动态积分模式（即每道题目的分值将根据解出队伍的数量进行动态计分，每多一队解出，该题目的分值会随之下降），最终成绩总分由高至低排列，分数相同情况下，以提交时间为准，用时短者排名高于用时较长者。
        </div>
        <div class="ctfCenterSevenTh1Right">
          <img src="../../../../assets/img/proctCenter/4.webp" alt="" />
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import { WOW } from "wowjs";
export default {
  data() {
    return {
      tabsName: [
        {
          name: "产品概述",
          isActive: true,
          names: "#newctfCenterThird",
        },
        {
          name: "产品功能",
          isActive: false,
          names: "#newctfCenterFourth",
        },
        {
          name: "产品优势",

          isActive: false,
        },
        {
          name: "用户价值",

          isActive: false,
        },
        {
          name: "典型场景",

          isActive: false,
        },
      ],
      active: 0,
    };
  },
  mounted() {
    // 监听滚动事件
    window.addEventListener("scroll", this.scrollLength, false);
    //第一种写法，可以设置wow中属性
    this.$nextTick(() => {
      // 在dom渲染完后,再执行动画
      var wow = new WOW({
        boxClass: "wow", ///动画元件css类（默认为wow）
        animateClass: "animated", //动画css类（默认为animated）
        offset: 0, //到元素距离触发动画（当默认为0）
        mobile: true, //在移动设备上触发动画（默认为true）
        live: true, //对异步加载的内容进行操作（默认为true）
      });
      wow.init();
    });
  },
  destroy() {
    // 必须移除监听器，不然当该vue组件被销毁了，监听器还在就会出错
    window.removeEventListener("scroll", this.scrollLength);
  },
  methods: {
    /* 监听滚动(结合前面的@scroll) */
    scrollEvent(e) {
      let scrollItems = document.querySelectorAll(".d_jump");
      for (let i = scrollItems.length - 1; i >= 0; i--) {
        // 判断滚动条滚动距离是否大于当前滚动项可滚动距离
        let judge =
          e.target.scrollTop >=
          scrollItems[i].offsetTop - scrollItems[0].offsetTop;
        if (judge) {
          this.catalogCur = i;
          break;
        }
      }
      // 滚动条触底了
      if (
        e.srcElement.scrollTop + e.srcElement.offsetHeight ===
        e.srcElement.scrollHeight
      ) {
        this.catalogCur = this.articleName.length - 1;
      }

      console.log(e.srcElement.scrollTop); // 滚动条高度
      console.log(e.srcElement.offsetHeight); // 屏幕高度
      console.log(e.srcElement.scrollHeight); // 内容高度
      // '下拉获取更多'的功能(还有10像素就触发)：滚动的像素+容器的高度>可滚动的总高度-10像素
    },
    tabsSwitch(indexs) {
      this.active = indexs;
      let jump = document.querySelectorAll(".d_jump");
      jump[indexs].scrollIntoView({ block: "start", behavior: "smooth" });
    },
  },
};
</script>

<style lang="less" scoped>
@media only screen and (max-width: 767px) {
  .ctfCenterAlls {
    width: 100%;
    .box {
      margin: 0 auto;
      padding: 0px 40px;
    }
    .tabs {
      display: flex;
      justify-content: flex-start;
      height: 65px;
      border-bottom: 1px solid #dadada;
    }
    .tabs li {
      // margin-right: 87px;
      list-style: none;
    }

    .tabs .tab-link {
      display: block;
      width: 70px;
      height: 65px;
      text-align: center;
      line-height: 65px;
      font-size: 14px;
      color: #000;
      text-decoration: none;
    }
    .tabs .tab-link:hover {
      cursor: pointer;
      height: 65px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border-bottom: 5px solid #3d4ed0;
      transition: 0.3s;
    }

    .tabs .tab-link.active {
      height: 65px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border-bottom: 5px solid #3d4ed0;
      transition: 0.3s;
    }
    .ctfCenterFirst {
      height: 300px;
      background-image: url(../../../../assets/img/proctCenter/7.jpg);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      padding: 80px 40px;
      .ctfCenterFirst1 {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #fff;
        h2 {
          font-weight: bold;
          font-size: 18px;
          margin-bottom: 30px;
          color: #fff;
        }
        h3 {
          font-size: 16px;
          text-align: left;
          margin-bottom: 20px;
        }
      }
    }

    .ctfCenterThird {
      margin-top: 20px;
      h1 {
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 30px;
        text-align: center;
      }
      .ctfCenterThird1 {
        margin-top: 20px;
        background-image: url(../../../../assets/img/proctCenter/9.png);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        padding: 20px 40px;
        .ctfCenterThird2 {
          height: 50%;
          background-color: #fff;
          display: flex;
          opacity: 0.8;
          flex-direction: column-reverse;
          align-items: flex-start;
          padding: 50px;
          .ctfCenterThird2Left {
            p {
              text-indent: 1.5rem;
              font-size: 14px;
              color: #51565d;
              line-height: 20px;
              margin-top: 10px;
            }
          }
          .ctfCenterThird2Right {
            img {
              width: 60vw;
              height: 38vh;
            }
          }
        }
      }
    }
    .ctfCenterFourth {
      margin-top: 40px;
      h1 {
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 30px;
        text-align: center;
      }

      .ctfCenterFourth1 {
        background-color: #f1f5ff;
        padding: 20px 40px;
        .animated {
          animation-duration: 1s;
          animation-fill-mode: both;
        }
        .ctfCenterFourth11 {
          display: flex;
          flex-direction: column;
          margin: 0 auto;
          .ctfCenterFourth1-1 {
            width: 263px;
            background-color: #fff;
            padding: 30px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-left: 20px;
            margin-top: 20px;
            .ctfCenterFourth1-1-1 {
              display: flex;
              align-items: center;
              justify-content: center;
              img {
                width: 50px;
                height: 50px;
              }
            }
            p {
              text-indent: 2rem;
              font-size: 14px;
              font-weight: 200;
              line-height: 30px;
            }
            .ctfCenterFourth1-2 {
              width: 100px;
              margin-top: auto;
              border-top: 5px solid #3d4ed0;
              text-align: center;
            }
          }
        }
      }
    }
    .ctfCenterFiveth {
      margin-top: 40px;
      h1 {
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 30px;
        text-align: center;
      }
      .ctfCenterFiveth1 {
        padding: 20px 40px;
        .ctfCenterFiveth11 {
          display: flex;
          flex-direction: column;
          align-items: center;
          .ctfCenterFiveth1-1 {
            width: 303px;
            background-color: #fff;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: 20px;
            .ctfCenterFiveth1-1-1 {
              display: flex;
              align-items: center;
              justify-content: center;
              img {
                width: 50px;
                height: 50px;
              }
            }
            p {
              text-indent: 1.5rem;
              font-size: 14px;
              color: #51565d;
              line-height: 20px;
              margin-top: 10px;
            }
          }
        }
      }
    }
    .ctfCenterSixTh {
      margin-top: 40px;
      padding: 20px 40px;
      background-color: #f1f5ff;
      h1 {
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 30px;
        text-align: center;
      }
      p {
        text-indent: 1.5rem;
        font-size: 14px;
        color: #51565d;
        line-height: 20px;
        margin-top: 10px;
      }
      .ctfCenterSixTh1 {
        padding: 30px;
        height: 70%;
        background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        .ctfCenterSixTh1Left {
          width: 70%;
          p {
            text-indent: 1.5rem;
            font-size: 14px;
            color: #51565d;
            line-height: 20px;
            margin-top: 10px;
          }
          h2 {
            font-size: 16px;
            text-align: center;
            margin-bottom: 20px;
          }
        }
        .ctfCenterSixTh1Right {
          img {
            width: 30vw;
            height: 20vh;
          }
        }
      }
    }
    .codeAuditThird {
      width: 100%;
      margin-top: 40px;
      background: #f1f5ff;
      padding: 20px 40px;
      h1 {
        text-align: center;
      }
      .codeAuditThird11 {
        width: 220px;
        text-align: center;
        margin-top: 20px;
        font-size: 20px;
      }
      ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 40px;
        li {
          display: flex;
          padding: 20px;
          background-image: linear-gradient(180deg, #f4f7ff, #fcfcfe);
          border-radius: 12px;
          margin-top: 20px;
          .codeAuditThird1-1 {
            img {
              width: 48px;
              height: 48px;
              margin-right: 20px;
            }
          }
          .codeAuditThird1-2 {
            .codeAuditThird1-2-1 {
              font-size: 24px;
              color: #1f2329;
            }
            .codeAuditThird1-2-2 {
              font-size: 16px;
              color: #51565d;
              margin-top: 20px;
              line-height: 26px;
            }
          }
        }
      }
    }
    .ctfCenterSevenTh {
      margin-top: 40px;
      padding: 30px;
      background-color: #f1f5ff;
      h1 {
        text-align: center;
      }
      .ctfCenterSevenTh1 {
        padding: 30px;
        width: 70%;
        height: 70%;
        margin: 50px auto;
        background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        .ctfCenterSevenTh1Left {
          width: 40%;
          text-indent: 2rem;
          font-size: 18px;
          padding: 50px;
          font-weight: 200;
          line-height: 30px;
          h2 {
            margin-bottom: 30px;
          }
        }
        .ctfCenterSevenTh1Right {
          max-width: 50%;
          img {
            width: 474px;
            height: 420px;
          }
        }
      }
    }
  }
}
</style>